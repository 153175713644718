/*!
 * This file was created to avoid imports from 'bitmovin-player' package,
 * when we need to use enum HttpRequestType as value and not as type.
 */

export enum HttpRequestType {
  DRM_CERTIFICATE_FAIRPLAY = 'drm/certificate/fairplay',
  DRM_LICENSE_CLEARKEY = 'drm/license/clearkey',
  DRM_LICENSE_FAIRPLAY = 'drm/license/fairplay',
  DRM_LICENSE_PLAYREADY = 'drm/license/playready',
  DRM_LICENSE_PRIMETIME = 'drm/license/primetime',
  DRM_LICENSE_WIDEVINE = 'drm/license/widevine',
  INTERNAL = 'internal',
  KEY_HLS_AES = 'key/hls/aes',
  MANIFEST_ADS = 'manifest/ads',
  MANIFEST_DASH = 'manifest/dash',
  MANIFEST_HLS_MASTER = 'manifest/hls/master',
  MANIFEST_HLS_VARIANT = 'manifest/hls/variant',
  MANIFEST_SMOOTH = 'manifest/smooth',
  MEDIA_AUDIO = 'media/audio',
  MEDIA_SEGMENTINDEX = 'media/segmentindex',
  MEDIA_SUBTITLES = 'media/subtitles',
  MEDIA_THUMBNAILS = 'media/thumbnails',
  MEDIA_VIDEO = 'media/video',
  TIME_SYNC = 'time/sync',
  UNKNOWN = 'unknown',
  WEBRTC_SDP_ANSWER = 'webrtc/sdp/answer',
  WEBRTC_SDP_OFFER = 'webrtc/sdp/offer',
  WEBRTC_SDP_REQUEST = 'webrtc/sdp/request',
}
