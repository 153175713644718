import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {SEQUENCE_NUMBER_LIMIT} from '../utils/Settings';

import {Backend} from './Backend';

/**
 * The `SequenceNumberBackend` is a decorator class that increments a samples sequence number and filters out samples
 * where the `sequenceNumber` exceeds a specified threshold.
 *
 * If a sample's `sequenceNumber` surpasses the threshold, it is not forwarded to the next backend.
 * Otherwise, the sample is passed along to the next backend for further processing.
 *
 * @implements {Backend}
 */
export class SequenceNumberBackend implements Backend {
  nextBackend: Backend;
  sequenceNumber = 0;

  constructor(underlying: Backend) {
    this.nextBackend = underlying;
  }

  sendRequest(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    if (this.isSequenceNumberAboveThreshold(sample)) {
      return;
    }
    this.nextBackend.sendRequest(sample);
  }

  sendUnloadRequest(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    if (this.isSequenceNumberAboveThreshold(sample)) {
      return;
    }
    this.nextBackend.sendUnloadRequest(sample);
  }

  sendRequestSynchronous(sample: Sample) {
    this.updateSampleBeforeSending(sample);
    if (this.isSequenceNumberAboveThreshold(sample)) {
      return;
    }
    this.nextBackend.sendRequestSynchronous(sample);
  }

  sendAdRequest(sample: AdAnalyticsSample & AdBreakSample & AdSample) {
    if (this.isSequenceNumberAboveThreshold(sample)) {
      return;
    }
    this.nextBackend.sendAdRequest(sample);
  }

  private updateSampleBeforeSending(sample: Sample) {
    sample.sequenceNumber = this.sequenceNumber++;
  }

  private isSequenceNumberAboveThreshold(sample: Sample): boolean {
    return (sample.sequenceNumber ?? 0) > SEQUENCE_NUMBER_LIMIT;
  }
}
