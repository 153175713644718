"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const show_model_1 = require("./show-model");
const isShowItem = (0, json_schema_1.createValidator)(show_model_1.ShowJson);
function showFromJson(item, mode) {
    if (isShowItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            isDefault: item.isDefault ?? false,
            name: item.name,
            siteId: item.siteId,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isShowItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Show`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Show`.
 */
function showToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        isDefault: record.isDefault,
        name: record.name,
        siteId: record.siteId,
    };
}
exports.ShowJsonMapper = {
    fromJSON: showFromJson,
    toJSON: showToJson,
};
