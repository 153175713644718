"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleJson = exports.ModuleModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of attendee data records.
 */
exports.ModuleModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    siteID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    pageID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    pageIndex: (0, model_helpers_1.Nullable)(typebox_1.Type.Integer()),
    coreID: typebox_1.Type.String({ format: 'uuid' }),
    parentID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    parentSlotSlug: (0, model_helpers_1.Nullable)(typebox_1.Type.String()),
    parentSlotIndex: (0, model_helpers_1.Nullable)(typebox_1.Type.Integer()),
    variables: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    adminUI: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    path: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' })),
    groupID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.ModuleJson = exports.ModuleModel;
