"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainManagementMessage = exports.ValidateDistributionMessage = exports.DistributionAssociatedMessage = exports.DistributionCreatedMessage = exports.CertificateValidMessage = exports.CertificateInvalidMessage = exports.CertificateCreatedMessage = exports.DomainCreatedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model/model-helpers");
const shared_1 = require("../shared");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
exports.DomainCreatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain created',
        }),
        siteId: typebox_1.Type.String({
            title: 'Site Id',
            description: 'Identifier of the Site record for which a domain was created',
            format: 'uuid',
        }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Domain Created'),
});
exports.CertificateCreatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain created',
        }),
        organizationId: typebox_1.Type.String({
            title: 'Organization Id',
            description: 'Identifier of the Organization for which a domain was created',
            format: 'uuid',
        }),
        certificateArn: typebox_1.Type.String({
            title: 'Certificate ARN',
            description: 'Used to make describe certificate',
        }),
        /**
         * List of record name and value pairs for validating certificate associated
         * with the site
         */
        certificateVerification: typebox_1.Type.Array(shared_1.CertificateVerificationRecord),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Certificate Created'),
});
exports.CertificateInvalidMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        certificateArn: typebox_1.Type.String({
            title: 'Certificate ARN',
            description: 'The certificate ARN which comes back from requesting a certificate, will be used to create a distribution',
        }),
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain created',
        }),
        organizationId: typebox_1.Type.String({
            title: 'Organization Id',
            description: 'Identifier of the Organization for which a domain was created',
            format: 'uuid',
        }),
        reason: typebox_1.Type.Optional(typebox_1.Type.String({
            title: 'The reason the validation failed',
            description: 'Reason provided for why certificate validation could not be completed.',
        })),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Certificate Invalid'),
});
exports.CertificateValidMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain being validated',
        }),
        certificateArn: typebox_1.Type.String({
            title: 'Certificate ARN',
            description: 'The certificate ARN which comes back from requesting a certificate, will be used to create a distribution',
        }),
        organizationId: typebox_1.Type.String({
            title: 'Organization Id',
            description: 'Identifier of the Organization for which a domain was created',
            format: 'uuid',
        }),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Certificate Valid'),
});
const DistributionErrorMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** Name of the domain which could not be associated */
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain for which a distribution is being created',
        }),
        /** Details indicating what went wrong */
        reason: (0, model_helpers_1.JsonObject)({
            title: 'Error Reason',
            description: 'Message indicating what went wrong',
        }),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Distribution Error'),
});
exports.DistributionCreatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain for which a distribution is being created',
        }),
        distributionArn: typebox_1.Type.String({
            title: 'Distribution ARN',
            description: 'The distribution ARN which comes back from creating a certificate, will be used to validate a distribution',
        }),
        /** Domain name of the CloudFront Distribution associated with the site */
        distributionDomainName: typebox_1.Type.String(),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Distribution Created'),
});
exports.DistributionAssociatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain being associated with a distribution',
        }),
    }),
    source: message_source_1.DomainManagementSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Distribution Associated'),
});
exports.ValidateDistributionMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        domainName: typebox_1.Type.String({
            title: 'Domain of Site',
            description: 'The domain name of the domain being associated with a distribution',
        }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Validate Distribution'),
});
exports.DomainManagementMessage = typebox_1.Type.Union([
    exports.DomainCreatedMessage,
    exports.CertificateCreatedMessage,
    exports.CertificateInvalidMessage,
    exports.CertificateValidMessage,
    exports.DistributionCreatedMessage,
    exports.DistributionAssociatedMessage,
    DistributionErrorMessage,
    exports.ValidateDistributionMessage,
]);
