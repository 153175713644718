import {
  extractInstruction,
  reduceVariableInstructions,
  type ApiInstruction,
  type Instruction,
} from '@backstage/instructions';
import {type MutableRefObject} from 'react';
import {EMPTY, map, type Observable, type Subject} from 'rxjs';
import {
  assign,
  createMachine,
  send,
  type Actions,
  type DoneInvokeEvent,
  type InvokeCreator,
} from 'xstate';
import {type BroadcastFunction} from './transformations/node.types';
import type {FetchInstructionsFn, ShowFetchInstructionsFn} from './types';

/**
 * Create a state machine which manages the retrieval of new instructions.
 * Retrieved instructions are passed into the given `Subject` when returned by
 * the given `getInstructions` function. `getInstructions` is invoked when the
 * state machine enters the `PENDING` state.
 */
export const createInstructionFetchMachine = (
  options: InstructionBaseContext
): typeof showInstructionsMachine => {
  return showInstructionsMachine.withContext({
    getInstructions: options.getInstructions,
    subject: options.subject,
    track: options.track,
  });
};

type TransitionEvent<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>
> = Data extends Record<string, never> ? {type: Kind} : {type: Kind} & Data;

interface InstructionBaseContext {
  /** Ref for function used when retrieving instructions */
  getInstructions: MutableRefObject<ShowFetchInstructionsFn>;
  /** Subject into which newly retrieved instructions are pushed */
  subject: Subject<Instruction[] | Error>;
  /** Function called push instructions into an analytics batch */
  track: BroadcastFunction;
}

interface InstructionTypestateContext {
  observable?: Observable<ApiInstruction[]>;
  sinceInstructionId?: string;
  error?: Error;
  lastInstructions?: ApiInstruction[];
}

type InstructionContext = InstructionBaseContext & InstructionTypestateContext;

type Action =
  | TransitionEvent<'INIT'>
  | TransitionEvent<'FETCH'>
  | TransitionEvent<'AWAIT_MORE'>
  | TransitionEvent<'RECEIVE', {instructions: ApiInstruction[]}>
  | TransitionEvent<'REQUEST_ERROR', {error: Error}>
  | TransitionEvent<
      'REQUEST_COMPLETE',
      {instructions: ApiInstruction[]; latestInstructionId?: string}
    >
  | TransitionEvent<
      'SUBSCRIBE',
      {observable: Observable<ApiInstruction[]>; latestInstructionId?: string}
    >;

interface TS<
  Value extends string,
  Context extends InstructionTypestateContext = Record<string, never>
> {
  value: Value;
  context: InstructionBaseContext & Context;
}

type InstructionTypestate =
  | TS<'INITIALIZING'>
  | TS<'WAITING', Pick<InstructionTypestateContext, 'sinceInstructionId'>>
  | TS<'PENDING'>
  | TS<'LISTENING', Required<Pick<InstructionTypestateContext, 'observable'>>>
  | TS<'ERROR', Required<Pick<InstructionTypestateContext, 'error'>>>
  | TS<'DONE', Required<Pick<InstructionTypestateContext, 'lastInstructions'>>>;

type FetchInstructionsResponse = Awaited<ReturnType<FetchInstructionsFn>>;

/** Type alias for xstate event triggered when `pendingInvoke` rejects */
type OnError = DoneInvokeEvent<unknown>;

/** Type alias for xstate event triggered when `pendingInvoke` resolves */
type OnResolve = DoneInvokeEvent<FetchInstructionsResponse>;

/** Promise creator called when transitioning into the `PENDING` state */
const pendingInvoke: InvokeCreator<
  InstructionContext,
  Action,
  FetchInstructionsResponse
> = (context) => {
  const getInstructions = context.getInstructions.current;
  if (typeof getInstructions === 'function') {
    return getInstructions(context.sinceInstructionId);
  } else {
    return Promise.resolve({});
  }
};

/** Actions to perform when `pendingInvoke` resolves */
const onDoneActions: Actions<InstructionContext, OnResolve> = [
  send((_, event: OnResolve): Action => {
    const result = event.data;
    if ('observable' in result) {
      return {
        type: 'SUBSCRIBE',
        observable: result.observable,
        latestInstructionId: result.latestInstructionId ?? undefined,
      };
    } else if (
      typeof result.data?.showById !== 'undefined' &&
      result.data?.showById !== null
    ) {
      return {
        type: 'REQUEST_COMPLETE',
        instructions: result.data.showById.showInstructions,
        latestInstructionId:
          result.data.showById.latestInstructionId ?? undefined,
      };
    } else if (typeof result.error !== 'undefined') {
      return {type: 'REQUEST_ERROR', error: result.error};
    } else {
      return {type: 'REQUEST_COMPLETE', instructions: []};
    }
  }),
];

/** Actions to perform when `pendingInvoke` rejects */
const onErrorActions: Actions<InstructionContext, OnError> = [
  send((context, event): Action => {
    const reason: unknown = event.data;
    return {
      type: 'REQUEST_ERROR',
      error: reason instanceof Error ? reason : new Error(),
    };
  }),
];

const showInstructionsMachine = createMachine<
  InstructionContext,
  Action,
  InstructionTypestate
>(
  {
    predictableActionArguments: true,
    id: 'instructions',
    initial: 'INITIALIZING',
    states: {
      INITIALIZING: {
        on: {INIT: {target: 'WAITING'}},
      },
      WAITING: {
        on: {
          FETCH: {
            target: 'PENDING',
          },
        },
      },
      PENDING: {
        invoke: {
          id: 'fetch',
          src: pendingInvoke,
          onDone: {actions: onDoneActions},
          onError: {actions: onErrorActions},
        },
        on: {
          REQUEST_ERROR: {
            actions: assign({
              error: (_context, event) => event.error,
            }),
            target: 'ERROR',
          },
          REQUEST_COMPLETE: {
            actions: assign({
              lastInstructions: (_context, event) => {
                return event.instructions.slice(0);
              },
              sinceInstructionId: (context, event) => {
                const lastInstruction = event.instructions.slice(-1)[0];
                if (event.latestInstructionId) {
                  return event.latestInstructionId;
                } else if (typeof lastInstruction !== 'undefined') {
                  // This case should be unreachable, but is being left in for safety.
                  return lastInstruction.id;
                } else {
                  return context.sinceInstructionId;
                }
              },
            }),
            target: 'DONE',
          },
          SUBSCRIBE: {
            actions: assign({
              observable: (_context, event) => event.observable,
              sinceInstructionId: (_context, event) =>
                event.latestInstructionId,
            }),
            target: 'LISTENING',
          },
        },
      },
      LISTENING: {
        invoke: {
          src: (context, event) =>
            event.type === 'SUBSCRIBE'
              ? event.observable.pipe(
                  map(
                    (instructions): Action => ({type: 'RECEIVE', instructions})
                  )
                )
              : EMPTY,
          // Go to `WAITING` when the observable completes so `getInstructions`
          // is triggered again quickly. Going to `DONE` waits 5 seconds before
          // re-fetching.
          onDone: {target: 'WAITING'},
          onError: {target: 'ERROR'},
        },
        on: {
          RECEIVE: {
            actions: [
              assign({
                lastInstructions: (context, event) => {
                  return event.instructions.slice(0);
                },
                sinceInstructionId: (context, event) => {
                  const lastInstruction = event.instructions.slice(-1)[0];
                  return lastInstruction?.id;
                },
              }),
              (context, event) => {
                applyInstructions(context, event.instructions);
              },
            ],
          },
        },
      },
      ERROR: {
        entry: (context) => {
          if (context.error instanceof Error) {
            context.subject.next(context.error);
          }
        },
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
      DONE: {
        entry: ['pushInstructions'],
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
    },
  },
  {
    actions: {
      pushInstructions: (context) => {
        if (Array.isArray(context.lastInstructions)) {
          applyInstructions(context, context.lastInstructions);
        }
      },
    },
  }
);

/**
 * For each `ApiInstruction` received push it into the `Subject` and track it
 * via the analytics `BroadcastFunction` as long as the `sinceInstructionId` is
 * set indicating this is not the initial payload.
 */
function applyInstructions(
  context: InstructionContext,
  apiInstructions: ApiInstruction[]
): void {
  const {actions, setters} = reduceVariableInstructions(apiInstructions);
  const instructions = setters.concat(actions).map(extractInstruction);
  context.subject.next(instructions);
  // `sinceInstructionId` is always set after the first payload is received,
  // this avoids tracking the initial payload
  if (typeof context.sinceInstructionId !== 'undefined') {
    instructions.forEach((instruction) => context.track(instruction, 'api'));
  }
}
