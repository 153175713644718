"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertNever = void 0;
/**
 * Asserts that the given object has the `never` type indicating all branches of
 * the union have been consumed.
 * @param input to assert does not exist
 * @throws when executed, this is a runtime enforcement of a type level check.
 */
function assertNever(input) {
    throw new Error(`Received unhandled event ${input}`);
}
exports.assertNever = assertNever;
