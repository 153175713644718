"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteVersionMessage = exports.SiteVersionSnapshotCompleteMessage = exports.SiteVersionReadyMessage = exports.SiteVersionErrorMessage = exports.SiteVersionDeployedMessage = exports.SiteVersionDeployMessage = exports.SiteVersionCreatedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const SiteVersionCreatedDetailType = 'SiteVersion Created';
const SiteVersionCreatedDetail = typebox_1.Type.Object({
    domainName: typebox_1.Type.String({
        title: 'Domain of Site',
        description: 'The domain associated with Site to which the version will be published',
    }),
    siteId: typebox_1.Type.String({
        title: 'Site Id',
        description: 'Identifier of the Site record for which a version was created',
        format: 'uuid',
    }),
    versionId: typebox_1.Type.String({
        title: 'Version Id',
        description: 'Identifier of the SiteVersion record created',
        format: 'uuid',
    }),
    withPublishedApi: typebox_1.Type.Boolean({
        title: 'Use Published API',
        description: 'Whether to use the "published data" api or the "draft data" api.',
    }),
}, {
    title: SiteVersionCreatedDetailType,
    description: 'Expected shape of the Detail field of message emitted when a new SiteVersion is created',
});
exports.SiteVersionCreatedMessage = typebox_1.Type.Object({
    detail: SiteVersionCreatedDetail,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(SiteVersionCreatedDetailType),
});
const SiteVersionDeployDetailType = 'SiteVersion Deploy';
const SiteVersionDeployDetail = typebox_1.Type.Pick(SiteVersionCreatedDetail, ['domainName', 'siteId', 'versionId'], {
    title: SiteVersionDeployDetailType,
    description: 'Detail fields of a message emitted when a SiteVersion is to be deployed',
});
exports.SiteVersionDeployMessage = typebox_1.Type.Object({
    detail: SiteVersionDeployDetail,
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(SiteVersionDeployDetailType),
});
const SiteVersionDeployedDetailType = 'SiteVersion Deployed';
const SiteVersionDeployedDetail = typebox_1.Type.Pick(SiteVersionCreatedDetail, ['domainName', 'siteId', 'versionId'], {
    title: SiteVersionDeployedDetailType,
    description: 'Detail fields of a message emitted when a SiteVersion has been deployed',
});
exports.SiteVersionDeployedMessage = typebox_1.Type.Object({
    detail: SiteVersionDeployedDetail,
    source: message_source_1.SitePublishSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(SiteVersionDeployedDetailType),
});
exports.SiteVersionErrorMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Intersect([
        typebox_1.Type.Pick(SiteVersionCreatedDetail, [
            'domainName',
            'siteId',
            'versionId',
        ]),
        typebox_1.Type.Object({
            reason: typebox_1.Type.Any({
                title: 'Error Reason',
                description: 'Indicates the cause of the failure, see https://docs.aws.amazon.com/step-functions/latest/dg/concepts-error-handling.html',
            }),
        }),
    ], {
        title: 'SiteVersion Error Details',
        description: 'Detail fields of a message emitted when a SiteVersion has been deployed',
    }),
    source: message_source_1.SitePublishSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('SiteVersion Error'),
});
const SiteVersionReadyDetailType = 'SiteVersion Ready';
const SiteVersionReadyDetail = typebox_1.Type.Object(SiteVersionCreatedDetail.properties, {
    title: SiteVersionReadyDetailType,
    description: 'Detail fields of a message emitted when a SiteVersion is ready to be deployed',
});
exports.SiteVersionReadyMessage = typebox_1.Type.Object({
    detail: SiteVersionReadyDetail,
    source: message_source_1.SitePublishSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal(SiteVersionReadyDetailType),
});
exports.SiteVersionSnapshotCompleteMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object(SiteVersionCreatedDetail.properties, {
        description: 'Detail fields of a message emitted when a SiteVersion snapshot is complete',
    }),
    source: message_source_1.SitePublishSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('SiteVersion SnapshotComplete'),
});
exports.SiteVersionMessage = typebox_1.Type.Union([
    exports.SiteVersionCreatedMessage,
    exports.SiteVersionDeployMessage,
    exports.SiteVersionDeployedMessage,
    exports.SiteVersionErrorMessage,
    exports.SiteVersionReadyMessage,
    exports.SiteVersionSnapshotCompleteMessage,
]);
