import {logger} from '../utils/Logger';
import {isValidString} from '../utils/stringUtils';
import {toBoolean} from '../utils/Utils';

import type {
  AdPosition,
  SsaiAdBreakMetadata,
  SsaiAdMetadata,
  SsaiAdQuartile,
  SsaiAdQuartileMetadata,
} from './AdapterAPI';

export function isAdPosition(anything: unknown): anything is AdPosition {
  // invalid, when not defined as one of string values we expect
  return anything === 'preroll' || anything === 'midroll' || anything === 'postroll';
}

/** User input validation, if passed SSAI API argument is valid */
export function isValidSsaiAdBreakMetadata(anything: unknown): boolean {
  // value `undefined` / `null` is considered to be valid, because that value is optional
  if (anything == null) {
    return true;
  }

  // invalid, when value was defined, but not as an object
  if (typeof anything !== 'object') {
    logger.errorMessageToUser('Invalid SsaiAdBreakMetadata: not an object');
    return false;
  }

  const maybeSsaiAdBreakMetadata = anything as SsaiAdBreakMetadata;

  // invalid, when `adPosition` was defined, but not as an ad position string we expect
  if (maybeSsaiAdBreakMetadata.adPosition != null && !isAdPosition(maybeSsaiAdBreakMetadata.adPosition)) {
    logger.errorMessageToUser("Invalid SsaiAdBreakMetadata: 'adPosition' property is not valid");
    return false;
  }

  return true;
}

/** User input validation, if passed SSAI API argument is valid */
export function isValidSsaiAdMetadata(anything: unknown): boolean {
  // value `undefined` / `null` is considered to be valid, because that value is optional
  if (anything == null) {
    return true;
  }

  // invalid, when value was defined, but not as an object
  if (typeof anything !== 'object') {
    logger.errorMessageToUser('Invalid SsaiAdMetadata: not an object');
    return false;
  }

  const maybeSsaiAdMetadata = anything as SsaiAdMetadata;

  // invalid, when `adId` was defined, but not as a string
  if (maybeSsaiAdMetadata.adId != null && !isValidString(maybeSsaiAdMetadata.adId)) {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'adId' property is not string");
    return false;
  }

  // invalid, when `adSystem` was defined, but not as a string
  if (maybeSsaiAdMetadata.adSystem != null && !isValidString(maybeSsaiAdMetadata.adSystem)) {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'adSystem' property is not string");
    return false;
  }

  // invalid, when `customData` was defined, but not as an object
  if (maybeSsaiAdMetadata.customData != null && typeof maybeSsaiAdMetadata.customData !== 'object') {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'customData' property is not an object");
    return false;
  }

  return true;
}

function isSsaiAdQuartile(anything: unknown): anything is SsaiAdQuartile {
  return anything === 'completed' || anything === 'first' || anything === 'midpoint' || anything === 'third';
}

/** User input validation, if passed SSAI API argument is valid */
export function isValidSsaiAdQuartile(anything: unknown): boolean {
  if (!isSsaiAdQuartile(anything)) {
    logger.errorMessageToUser('Invalid SsaiAdQuartile');
    return false;
  }

  return true;
}

/**
 * Utility function to sanitize the failedBeaconUrl value in the SsaiAdQuartileMetadata (if defined), to be string
 * with max length 500 characters to guard our sent samples against big payloads
 */
export function sanitizeSsaiAdQuartileMetadataFailedBeaconUrl<T = unknown>(anything: T): T {
  const maybeSsaiAdQuartileMetadata = anything as SsaiAdQuartileMetadata;

  if (isValidString(maybeSsaiAdQuartileMetadata?.failedBeaconUrl)) {
    maybeSsaiAdQuartileMetadata.failedBeaconUrl = maybeSsaiAdQuartileMetadata.failedBeaconUrl.substring(0, 500);
  }

  return maybeSsaiAdQuartileMetadata as T;
}

export function isValidSsaiAdQuartileMetadata(anything: unknown): boolean {
  // value `undefined` / `null` is considered to be valid, because that value is optional
  if (anything == null) {
    return true;
  }

  // invalid, when value was defined, but not as an object
  if (typeof anything !== 'object') {
    logger.errorMessageToUser('Invalid SsaiAdQuartileMetadata: not an object');
    return false;
  }

  const maybeSsaiAdQuartileMetadata = anything as SsaiAdQuartileMetadata;

  // invalid, when `failedBeaconUrl` was defined but not as a string
  if (
    maybeSsaiAdQuartileMetadata.failedBeaconUrl != null &&
    !isValidString(maybeSsaiAdQuartileMetadata.failedBeaconUrl)
  ) {
    logger.errorMessageToUser("Invalid SsaiAdQuartileMetadata: 'failedBeaconUrl' property is not string");
    return false;
  }

  return true;
}

export function sanitizeSessionMetadataAudioLanguage(anything: unknown): string | undefined {
  // value `undefined` / `null` is considered to be valid (to "reset" session metadata property)
  if (anything == null) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return String(anything).substring(0, 50);
}

export function sanitizeSessionMetadataSubtitleEnabled(anything: unknown): boolean | undefined {
  // value `undefined` / `null` is considered to be valid (to "reset" session metadata property)
  if (anything == null) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return toBoolean(anything);
}

export function sanitizeSessionMetadataSubtitleLanguage(anything: unknown): string | undefined {
  // value `undefined` / `null` is considered to be valid (to "reset" session metadata property)
  if (anything == null) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return String(anything).substring(0, 100);
}
