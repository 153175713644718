"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJSONValue = exports.isJSONObject = void 0;
/**
 * Determine if a given `JSONValue` is an object.
 * @param o to be checked.
 * @returns `true` if the given value is an object (not an array or primitive).
 */
function isJSONObject(o) {
    return typeof o === 'object' && o !== null && !Array.isArray(o);
}
exports.isJSONObject = isJSONObject;
/**
 * Determine if a given value can be used as a `JSONValue`. Checks recursively
 * if `o` is an object or an array.
 * @param o to be checked.
 * @returns `true` if the given value can be used as a `JSONValue`.
 */
function isJSONValue(o) {
    return (o === null ||
        typeof o === 'string' ||
        typeof o === 'number' ||
        typeof o === 'boolean' ||
        (Array.isArray(o) && o.every((v) => isJSONValue(v))) ||
        (typeof o === 'object' &&
            Object.entries(o).every(([, value]) => isJSONValue(value))));
}
exports.isJSONValue = isJSONValue;
