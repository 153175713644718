"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSiteResourceData = exports.SiteResourceData = exports.isSiteResourcesV2 = exports.isSiteResourcesV1 = exports.SiteResourcesV2 = exports.SiteResourcesV1 = exports.isSiteResource = exports.SiteResource = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../../json-schema");
const model_helpers_1 = require("../model-helpers");
const SiteLinkResource = typebox_1.Type.Object({
    attributes: (0, model_helpers_1.JsonObject)(),
    href: typebox_1.Type.String(),
    rel: typebox_1.Type.String(),
});
const SiteScriptResource = typebox_1.Type.Object({
    src: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
});
exports.SiteResource = typebox_1.Type.Union([SiteLinkResource, SiteScriptResource]);
exports.isSiteResource = (0, json_schema_1.createValidator)(exports.SiteResource);
exports.SiteResourcesV1 = typebox_1.Type.Array(exports.SiteResource);
exports.SiteResourcesV2 = typebox_1.Type.Object({
    _version: typebox_1.Type.Literal(2),
    data: exports.SiteResourcesV1,
});
exports.isSiteResourcesV1 = (0, json_schema_1.createValidator)(exports.SiteResourcesV1);
exports.isSiteResourcesV2 = (0, json_schema_1.createValidator)(exports.SiteResourcesV2);
exports.SiteResourceData = typebox_1.Type.Union([exports.SiteResourcesV1, exports.SiteResourcesV2]);
exports.isSiteResourceData = (0, json_schema_1.createValidator)(exports.SiteResourceData);
