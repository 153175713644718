"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessCodeDynamoMapper = exports.AccessCodeByCodeIndex = void 0;
const dynamodb_mapper_1 = require("../dynamodb-mapper");
const access_code_json_1 = require("./access-code-json");
/**
 * Name of the secondary index which allows looking up an access code by showId
 * and access code value directly.
 */
exports.AccessCodeByCodeIndex = 'AccessCodeByCode';
exports.AccessCodeDynamoMapper = (0, dynamodb_mapper_1.createDynamoMapper)('AccessCode', access_code_json_1.AccessCodeJsonMapper);
