import addFormats from 'ajv-formats';
import Ajv, {Options} from 'ajv/dist/2019';
import {FLOW_IGNORE, PRIVATE_FIELD_DATA} from './helper-constants';

/**
 * Create an AJV validator configured with options and formats used by typebox.
 */
export function createSchemaValidator(options?: Options): Ajv {
  return addFormats(new Ajv(options ?? {}), [
    'date-time',
    'time',
    'date',
    'email',
    'hostname',
    'ipv4',
    'ipv6',
    'uri',
    'uri-reference',
    'uuid',
    'uri-template',
    'json-pointer',
    'relative-json-pointer',
    'regex',
  ])
    .addKeyword(FLOW_IGNORE)
    .addKeyword(PRIVATE_FIELD_DATA)
    .addKeyword('kind')
    .addKeyword('modifier');
}
