import {useEffect, useState} from 'react';

export const FALLBACK_LOCALE = 'en-US';
/**
 * Hook for determining a component's locale.
 * Checks {@link contextLocale} before referring to browser's Navigator object.
 * A fallback locale is set under {@link FALLBACK_LOCALE}
 * @param contextLocale A string from context that will set a component's locale
 * @returns A string describing locale.
 */
export const useLocale = (contextLocale?: string): string => {
  const [locale, setLocale] = useState(contextLocale);
  useEffect(() => {
    if (
      !locale &&
      navigator &&
      (navigator.language !== locale || navigator.languages?.[0] !== locale)
    ) {
      setLocale(navigator.language || navigator.languages?.[0]);
    }
  }, [locale, contextLocale]);
  return locale || FALLBACK_LOCALE;
};
