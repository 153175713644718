"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestSession = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const GuestSessionBase = typebox_1.Type.Object({
    /** Identifier for the instance */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Domain from which the authentication request originated */
    domainName: typebox_1.Type.String(),
    /** Identifier for the show to which the session belongs */
    showId: typebox_1.Type.String({ format: 'uuid' }),
    ...timestamp_json_1.TimestampsJson.properties,
});
const GuestSessionAccessCode = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Identifier for the access_code associated with the session */
        accessCodeId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier for the attendee associated with the session */
        attendeeId: typebox_1.Type.String({ format: 'uuid' }),
    }),
]);
const GuestSessionPassCode = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Name associated with the guest */
        name: typebox_1.Type.String(),
        /** Identifier for the passcode used for authentication. */
        passcodeId: typebox_1.Type.String(),
    }),
]);
exports.GuestSession = typebox_1.Type.Union([GuestSessionAccessCode, GuestSessionPassCode], { $id: 'GuestSessionModel' });
