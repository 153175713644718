"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetManagementEvent = exports.AssetFinalizeEvent = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const model_1 = require("../model");
exports.AssetFinalizeEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        assetId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('Asset Finalize'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
const AssetDeleteEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Pick(model_1.Asset, ['id', 'fileName', 'uri']),
    'detail-type': typebox_1.Type.Literal('Asset Delete'),
    source: message_source_1.AssetManagmentApiSource,
    traceId: tracing_1.TraceId,
});
exports.AssetManagementEvent = typebox_1.Type.Union([
    AssetDeleteEvent,
    exports.AssetFinalizeEvent,
]);
