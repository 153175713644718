"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioConfig = void 0;
const typebox_1 = require("@sinclair/typebox");
const data_1 = require("../../data");
const aws_1 = require("../aws");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
/**
 * Defines the JSON serialized shape of `audio_config` data records. The schema
 * is used to validate data going into and coming out of the `audio_config`
 * table so changes to this schema need to be backward compatible.
 */
exports.AudioConfig = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    uri: typebox_1.Type.String({ format: 'uri' }),
    language: typebox_1.Type.String({
        description: 'User displayable name for the language',
    }),
    slug: data_1.AssetLanguageCode,
    description: typebox_1.Type.Optional(aws_1.AwsMediaConvertStreamName),
    assetId: typebox_1.Type.String({ format: 'uuid' }),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
