import {Fragment, createElement, useMemo} from 'react';
import parse, {HTMLReactParserOptions, domToReact} from 'html-react-parser';
import {attributesToProps, DOMNode, Element} from 'html-react-parser';

/**
 * Checks if the given `domNode` is a `<script>` element.
 * @param domNode to check for `tagName`
 * @returns `true` iff `domNode` is `<script>` element.
 */
function isScriptElement(domNode: DOMNode): domNode is Element {
  return (
    domNode instanceof Element && domNode.tagName.toLowerCase() === 'script'
  );
}

/**
 * Runs `content` through `parse` from `html-react-parser`.
 * replaces `<script>` tags. @see {@link isScriptElement}
 * @param content plain text or `html` markup.
 * @returns parsed text via `html-react-parser`.
 */
export const useParsedText = (
  content: string
): string | JSX.Element | JSX.Element[] => {
  return useMemo(() => {
    const attrsToRemove = ['data-buffer', 'data-metadata'];
    const parseOptions: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (isScriptElement(domNode)) {
          return <Fragment />;
        } else if (
          domNode instanceof Element &&
          attrsToRemove.some((name) => name in domNode.attribs)
        ) {
          const attributes = Object.assign({}, domNode.attribs);
          for (const name of attrsToRemove) {
            delete attributes[name];
          }
          const props = attributesToProps(attributes);
          return createElement(
            domNode.tagName,
            props,
            domToReact(domNode.children, parseOptions)
          );
        }
      },
    };
    return parse(content, parseOptions);
  }, [content]);
};
