import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import type {Operation} from '@apollo/client';
import {readAccessToken} from '@backstage-components/attendee-container';
import {config} from './config';

const httpLink = new HttpLink({
  uri: `${config.endpointBase}/attendee`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const {showId} = operation.getContext();
  const accessToken = readAccessToken(showId);
  if (accessToken !== null) {
    applyAccessToken(operation, accessToken);
  }
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authMiddleware, httpLink]),
});

function applyAccessToken(operation: Operation, token: string): void {
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
