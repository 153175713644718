import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import React, {VFC} from 'react';
import {PublicAccessCodeComponent} from './PublicAccessCodeComponent';
import {SchemaType} from './PublicAccessCodeDefinition';

export type PublicAccessCodeProps = SchemaType;

export const PublicAccessCodePreviewComponent: VFC<
  ModuleNode<'PublicAccessCode', PublicAccessCodeProps>
> = (definition) => {
  const {component, config, props} = definition.module;
  return (
    <PublicAccessCodeComponent
      component={component}
      config={config}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
