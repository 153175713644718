import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import React, {VFC} from 'react';
import {SchemaType} from './TextInputDefinition';
import {ITextInputProps} from './TextInputComponent';
import {TextInputComponent} from '.';

export type TextInputProps = SchemaType & ITextInputProps;

export const TextInputPreviewComponent: VFC<
  ModuleNode<'TextInput', TextInputProps>
> = (definition) => {
  const {component, props} = definition.module;
  return (
    <TextInputComponent
      component={component}
      config={{scope: 'admin'}}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
