"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessCodeJson = exports.AccessCodeModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of access code data records.
 */
exports.AccessCodeModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    attendeeId: typebox_1.Type.String({ format: 'uuid' }),
    showId: typebox_1.Type.String({ format: 'uuid' }),
    attendeeAccessCode: typebox_1.Type.String({
        description: 'The value of the access code an attendee uses to login, may or may not be encrypted',
    }),
    attendeeAccessCodeIV: (0, model_helpers_1.Nullable)(typebox_1.Type.String({
        description: 'If provided, indicates attendeeAccessCode is encrypted and must be decrypted before comparisons',
    })),
    useCount: typebox_1.Type.Integer({ default: 0 }),
    useCountLimit: typebox_1.Type.Integer({ default: 5 }),
    attendeeTags: typebox_1.Type.Array(typebox_1.Type.String()),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the JSON serialized shape of access code data records.
 */
exports.AccessCodeJson = typebox_1.Type.Union([
    // V34 Timestamp Columns
    exports.AccessCodeModel,
    // Base
    typebox_1.Type.Intersect([
        typebox_1.Type.Omit(exports.AccessCodeModel, ['createdAt', 'updatedAt']),
        timestamp_json_1.OldTimestampsJson,
    ]),
]);
