import {ModuleNode, extractModuleIdentifiers} from '@backstage-components/base';
import React, {VFC} from 'react';
import {ButtonComponent, IButtonProps} from './ButtonComponent';
import {SchemaType} from './ButtonDefinition';

export type ButtonProps = SchemaType & IButtonProps;

export const ButtonPreviewComponent: VFC<ModuleNode<'Button', ButtonProps>> = (
  definition
) => {
  const {props} = definition.module;
  return (
    <ButtonComponent
      config={{scope: 'admin'}}
      component={definition.module.component}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
