"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessCodeJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const access_code_model_1 = require("./access-code-model");
const isAccessCodeItem = (0, json_schema_1.createValidator)(access_code_model_1.AccessCodeJson);
function accessCodeFromJson(item, mode) {
    if (isAccessCodeItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            attendeeAccessCode: item.attendeeAccessCode,
            attendeeAccessCodeIV: item.attendeeAccessCodeIV,
            attendeeId: item.attendeeId,
            attendeeTags: item.attendeeTags,
            id: item.id,
            showId: item.showId,
            useCount: item.useCount,
            useCountLimit: item.useCountLimit,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAccessCodeItem.errors ?? [];
    }
}
function accessCodeToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        attendeeAccessCode: record.attendeeAccessCode,
        attendeeAccessCodeIV: record.attendeeAccessCodeIV,
        attendeeId: record.attendeeId,
        attendeeTags: record.attendeeTags,
        id: record.id,
        showId: record.showId,
        useCount: record.useCount,
        useCountLimit: record.useCountLimit,
    };
}
exports.AccessCodeJsonMapper = {
    fromJSON: accessCodeFromJson,
    toJSON: accessCodeToJson,
};
