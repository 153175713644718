"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJSONValue = exports.isJSONObject = exports.getPropsDiff = exports.applyPropsDiff = exports.setAtPath = exports.swapInSiteVariables = exports.buildXmlStructure = exports.getDisplayName = exports.computeId = exports.getModuleProps = exports.familyTree = exports.computePath = exports.isIdenticalFlatArray = exports.isModuleVariables = exports.isCoreType = exports.coreTypes = void 0;
var coreTypes_1 = require("./coreTypes");
Object.defineProperty(exports, "coreTypes", { enumerable: true, get: function () { return coreTypes_1.coreTypes; } });
Object.defineProperty(exports, "isCoreType", { enumerable: true, get: function () { return coreTypes_1.isCoreType; } });
var moduleTree_1 = require("./moduleTree");
Object.defineProperty(exports, "isModuleVariables", { enumerable: true, get: function () { return moduleTree_1.isModuleVariables; } });
Object.defineProperty(exports, "isIdenticalFlatArray", { enumerable: true, get: function () { return moduleTree_1.isIdenticalFlatArray; } });
Object.defineProperty(exports, "computePath", { enumerable: true, get: function () { return moduleTree_1.computePath; } });
Object.defineProperty(exports, "familyTree", { enumerable: true, get: function () { return moduleTree_1.familyTree; } });
Object.defineProperty(exports, "getModuleProps", { enumerable: true, get: function () { return moduleTree_1.getModuleProps; } });
Object.defineProperty(exports, "computeId", { enumerable: true, get: function () { return moduleTree_1.computeId; } });
Object.defineProperty(exports, "getDisplayName", { enumerable: true, get: function () { return moduleTree_1.getDisplayName; } });
Object.defineProperty(exports, "buildXmlStructure", { enumerable: true, get: function () { return moduleTree_1.buildXmlStructure; } });
Object.defineProperty(exports, "swapInSiteVariables", { enumerable: true, get: function () { return moduleTree_1.swapInSiteVariables; } });
Object.defineProperty(exports, "setAtPath", { enumerable: true, get: function () { return moduleTree_1.setAtPath; } });
var propsDiff_1 = require("./propsDiff");
Object.defineProperty(exports, "applyPropsDiff", { enumerable: true, get: function () { return propsDiff_1.applyPropsDiff; } });
Object.defineProperty(exports, "getPropsDiff", { enumerable: true, get: function () { return propsDiff_1.getPropsDiff; } });
var json_1 = require("./json");
Object.defineProperty(exports, "isJSONObject", { enumerable: true, get: function () { return json_1.isJSONObject; } });
Object.defineProperty(exports, "isJSONValue", { enumerable: true, get: function () { return json_1.isJSONValue; } });
