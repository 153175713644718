"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryShowById = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
/**
 * Query for `Show` details necessary for guests to authenticate.
 * @param $showId (Required) id of the page to be retrieved
 */
exports.QueryShowById = (0, graphql_tag_1.gql) `
  query ShowById($showId: UUID!) {
    show: showById(showId: $showId) {
      ...ShowFields
    }
  }
  ${fragments_1.FragmentShow}
`;
