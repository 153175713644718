import {HTML5AnalyticsStateMachine} from '../../analyticsStateMachines/HTML5AnalyticsStateMachine';
import {Analytics} from '../../core/Analytics';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {Event} from '../../enums/Event';
import {getMIMETypeFromFileExtension} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {getStreamTypeFromMIMEType} from '../../enums/StreamTypes';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {normalizeVideoDuration, PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {SegmentInfo} from '../../types/SegmentInfo';
import {StreamSources} from '../../types/StreamSources';
import {SubtitleInfo} from '../../types/SubtitleInfo';
import {logger} from '../../utils/Logger';
import {isVideoInFullscreen} from '../../utils/Utils';
import {InternalAdapter} from '../internal/InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import HTMLVideoElementStatisticsProvider from './player/HTMLVideoElementStatisticsProvider';

export abstract class HTML5InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  override get segments(): SegmentInfo[] {
    return [];
  }

  private static BUFFERING_TIMECHANGED_TIMEOUT_IN_MS = 1000;

  protected mediaElement: HTMLVideoElement | undefined;
  private mediaElementEventHandlers: Array<{event: string; handler: any}>;
  /** holds reference of original mediaElement.load */
  private mediaElementOriginalLoadFn?: () => void;

  readonly videoCompletionTracker: VideoCompletionTracker;
  protected needsFirstPlayIntent: boolean = true;
  private onBeforeUnLoadEvent = false;

  private bufferingTimeout?: number;
  private isBuffering: boolean = false;
  private isPaused: boolean = false;
  private isSeeking: boolean = false;
  /** Previous media element time in seconds */
  private previousMediaTimeInSeconds: number = 0;
  /** Previous client time in milliseconds */
  private previousClientTime: number;
  private needsReadyEvent: boolean;
  private previousMutedValue = false;
  private playerStatisticsProvider: HTMLVideoElementStatisticsProvider;

  protected constructor(mediaElement: HTMLVideoElement | undefined, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new HTML5AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);

    this.mediaElement = mediaElement;
    this.mediaElementEventHandlers = [];
    this.mediaElementOriginalLoadFn = undefined;

    this.bufferingTimeout = undefined;
    this.isBuffering = false;
    this.isPaused = false;
    this.isSeeking = false;
    this.previousMediaTimeInSeconds = 0;
    this.previousClientTime = 0;
    this.needsReadyEvent = true;
    this.needsFirstPlayIntent = true;
    this.videoCompletionTracker = new VideoCompletionTracker();
    this.playerStatisticsProvider = new HTMLVideoElementStatisticsProvider(mediaElement);
  }

  override resetSourceRelatedState() {
    super.resetSourceRelatedState();
    this.playerStatisticsProvider.reset();
    this.needsFirstPlayIntent = true;
    this.isBuffering = false;
    this.isPaused = false;
    this.isSeeking = false;
    this.previousMediaTimeInSeconds = 0;
    clearTimeout(this.bufferingTimeout);
  }

  override release() {
    this.unregisterMediaElement();
    super.release();
  }

  getPlayerName = () => Player.HTML5;
  getPlayerTech = () => 'html5';
  getAutoPlay = () => (this.mediaElement ? this.mediaElement.autoplay : false);
  getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  initialize(_analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    if (this.mediaElement) {
      // calling `setMediaElement` with `null` argument on purpose to register all media element event
      // listeners with existing/already set media element during adapter initialization.
      this.setMediaElement();
    }
    this.registerWindowEvents();
    return [];
  }

  isLive = () => {
    if (!this.mediaElement || isNaN(this.mediaElement.duration)) {
      return undefined;
    }

    return this.mediaElement.duration === Infinity;
  };

  getStreamSources(url: string | undefined): StreamSources {
    if (!url) {
      return {};
    }
    const streamType = this.getStreamType();
    switch (streamType) {
      case 'hls':
        return {m3u8Url: url};
      case 'dash':
        return {mpdUrl: url};
      default:
        return {progUrl: url};
    }
  }

  getCurrentPlaybackInfo(): PlaybackInfo {
    let info: PlaybackInfo = {
      ...this.getCommonPlaybackInfo(),
      ...this.getStreamSources(this.getStreamURL()),
      isLive: this.isLive(),
      size: isVideoInFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      droppedFrames: this.playerStatisticsProvider.getDroppedFrames(),
      // TODO: Implement `audioBitrate`
      // TODO: Implement `isCasting`
      // TODO: Implement getting `videoTitle` from player (currently only from the analytics config)
    };

    if (this.mediaElement) {
      info = {
        ...info,
        videoDuration: normalizeVideoDuration(this.mediaElement.duration),
        isMuted: this.mediaElement.muted,
        videoWindowHeight: this.mediaElement.height,
        videoWindowWidth: this.mediaElement.width,
      };
    }

    const qualityInfo = this.getCurrentQualityLevelInfo();
    if (qualityInfo) {
      info = {
        ...info,
        videoPlaybackHeight: qualityInfo.height,
        videoPlaybackWidth: qualityInfo.width,
        videoBitrate: qualityInfo.bitrate,
      };
    }

    const streamFormat = this.getStreamType();
    if (streamFormat != null) {
      this.sourceInfoFallbackService.setStreamFormat(streamFormat);
    }

    this.sourceInfoFallbackService.applyStreamFormat(info);

    return info;
  }

  protected get currentTime(): number {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    return this.mediaElement.currentTime;
  }

  /**
   * Used to setup against the media element.
   * We need this method to desynchronize construction of this class
   * and the actual initialization against the media element.
   * That is because at construction some media engine
   * may not already have the media element attached, for example
   * when passing in the DOM element is happening at once with passing the source URL
   * and can not be decoupled.
   * We are then awaiting an event from the engine and calling this with the media element
   * as argument from our sub-class.
   *
   * This method can also be called without arguments and then it will perform
   * initialization against the existing media element (should only be called once, will throw an error otherwise)
   *
   * It can also be used to replace the element.
   *
   *
   */
  setMediaElement(mediaElement: HTMLVideoElement | null = null) {
    // if called with argument (new media element)
    if (mediaElement) {
      // if we already have one, we need to unregister current one before replacing it
      if (this.mediaElement) {
        this.unregisterMediaElement();
      }

      // set the new media element
      this.mediaElement = mediaElement;
    }

    // if called without argument (and that is feature of function),
    // we assume media element is already set and ready to be registered

    //  ...if not, we throw an error
    if (!this.mediaElement) {
      throw new Error('No media element owned');
    }

    // ...if it was already registered, we throw an error
    if (this.mediaElementEventHandlers.length > 0) {
      throw new Error('Media element already set (only call this once)');
    }

    this.registerMediaElement();
    this.onMaybeReady();
  }

  abstract getPlayerVersion(): string;

  /**
   * Implemented by sub-class to deliver current quality-level info specific to media-engine.
   */
  abstract getCurrentQualityLevelInfo(): null | QualityLevelInfo;

  /**
   * Can be overriden by sub-classes
   * @returns {string}
   *
   */
  getMIMEType(): string | undefined {
    const streamUrl = this.getStreamURL();
    if (!streamUrl || streamUrl === undefined) {
      return;
    }
    return getMIMETypeFromFileExtension(streamUrl);
  }

  /**
   * Can be overriden by sub-classes
   * @returns {string}
   */
  getStreamType(): string | undefined {
    const mimetype = this.getMIMEType();
    if (mimetype) {
      return getStreamTypeFromMIMEType(mimetype);
    }

    return undefined;
  }

  /**
   * Can be overriden by subclasses.
   * @returns {string}
   */
  getStreamURL(): string | undefined {
    const mediaElement = this.mediaElement;
    if (!mediaElement) {
      return;
    }

    return mediaElement.src;
  }

  registerMediaElement() {
    const mediaElement = this.mediaElement;
    if (!mediaElement) {
      return;
    }

    // wrap the original Media Element `load` function
    this.mediaElementOriginalLoadFn = mediaElement.load;
    const originalLoad = this.mediaElementOriginalLoadFn;
    mediaElement.load = (...args: Parameters<typeof mediaElement.load>): void => {
      this.sourceChange({});
      originalLoad.apply(mediaElement, args);
    };

    this.listenToMediaElementEvent('loadstart', () => {
      log(`loadstart ${mediaElement.currentTime}`);
      this.sourceChange({});
    });

    this.listenToMediaElementEvent('loadedmetadata', () => {
      log(`loadedmetadata time:${mediaElement.currentTime} / readyState: ${mediaElement.readyState}`);

      // silent
      this.checkQualityLevelAttributes(true);

      // set streamFormat if possible,
      // loadedMetadata seems to be the first event where this could be available
      const streamFormat = this.getStreamType();
      if (streamFormat != null) {
        this.sourceInfoFallbackService.setStreamFormat(streamFormat);
      }

      if (this.mediaElement != null) {
        this.videoCompletionTracker.reset();
        this.videoCompletionTracker.setVideoDuration(this.mediaElement.duration);
        this.previousMutedValue = this.mediaElement.muted;
      }
      this.eventCallback(Event.SOURCE_LOADED, {});
    });

    // We need the PLAY event to indicate the intent to play
    // NOTE: use TIMECHANGED event on 'playing' and trigger PLAY as intended in states.dot graph
    this.listenToMediaElementEvent('play', () => {
      log(`play ${mediaElement.currentTime}`);
      const {currentTime} = mediaElement;

      this.needsFirstPlayIntent = false;

      this.eventCallback(Event.PLAY, {currentTime});
    });

    this.listenToMediaElementEvent('pause', () => {
      log(`pause ${mediaElement.currentTime}`);
      this.onPaused();
    });

    this.listenToMediaElementEvent('playing', () => {
      log(`playing ${mediaElement.currentTime}`);
      clearTimeout(this.bufferingTimeout);
      this.isPaused = false;

      // when the play attempt was before we have been attached to the player
      // no play event will set this to false -> collector is stuck in READY state
      this.needsFirstPlayIntent = false;
    });

    this.listenToMediaElementEvent('error', () => {
      log(`error ${mediaElement.currentTime}`);
      const {currentTime, error} = mediaElement;
      this.eventCallback(Event.ERROR, {
        currentTime,
        // See https://developer.mozilla.org/en-US/docs/Web/API/MediaError
        code: error?.code ?? undefined,
        message: error?.message ?? undefined,
        data: {},
      });
    });

    this.listenToMediaElementEvent('volumechange', () => {
      log(`volumechange ${mediaElement.currentTime}`);
      const {muted, currentTime, volume} = mediaElement;
      const isMuted = this.isAudioMuted(muted, volume);

      if (this.previousMutedValue !== isMuted) {
        if (isMuted) {
          this.eventCallback(Event.MUTE, {currentTime});
        } else {
          this.eventCallback(Event.UN_MUTE, {currentTime});
        }
        this.previousMutedValue = isMuted;
      }
    });

    this.listenToMediaElementEvent('seeking', () => {
      log(`seeking ${mediaElement.currentTime}`);
      const {currentTime} = mediaElement;

      this.eventCallback(Event.SEEK, {currentTime});
    });

    this.listenToMediaElementEvent('seeked', () => {
      log(`seeked ${mediaElement.currentTime}`);
      const {currentTime} = mediaElement;

      clearTimeout(this.bufferingTimeout);

      this.eventCallback(Event.SEEKED, {currentTime});
    });

    this.listenToMediaElementEvent('timeupdate', () => {
      log(`timeupdate ${mediaElement.currentTime}`);
      const {currentTime} = mediaElement;

      this.isBuffering = false;
      this.isSeeking = false;

      // silence events if we have not yet intended play
      if (this.needsFirstPlayIntent) {
        return;
      }

      if (!this.isPaused) {
        // This check ensures that the 'TIMECHANGED' event is only triggered when there is a measurable
        // change in 'currentTime'.
        // On Tizen and WebOS platforms, we observed cases where the 'timeupdate' event was triggered without
        // any actual change in the 'currentTime'. During bufferings this caused buffering - playing loops
        // with over 900 samples sent under one minute. (https://bitmovin.atlassian.net/browse/AN-4546)
        const absoluteTimeDeltaInMs = Math.abs(currentTime - this.previousMediaTimeInSeconds) * 1000;
        if (absoluteTimeDeltaInMs > 0) {
          this.eventCallback(Event.TIMECHANGED, {currentTime});
        }
      }

      this.checkQualityLevelAttributes();

      this.checkSeeking();

      // We are doing this in case we can not rely
      // on the "stalled" or "waiting" events in a specific browser
      // and to detect intrinsinc paused states (when we do not get a paused event)
      // but the player is paused already before attach or is paused from initialization on.
      this.checkPlayheadProgress();

      this.previousMediaTimeInSeconds = currentTime;
    });

    // The stalled event is fired when the user agent is trying to fetch media data,
    // but data is unexpectedly not forthcoming.
    // https://developer.mozilla.org/en-US/docs/Web/Events/stalled
    this.listenToMediaElementEvent('stalled', () => {
      // this event doesn't indicate buffering by definition (interupted playback),
      // only that data throughput to playout buffers is not as high as expected
      // It happens on Chrome every once in a while as SourceBuffer's are not fed
      // as fast as the underlying native player may prefer (but it does not lead to
      // interuption).
    });

    // The waiting event is fired when playback has stopped because of a temporary lack of data.
    // See https://developer.mozilla.org/en-US/docs/Web/Events/waiting
    this.listenToMediaElementEvent('waiting', () => {
      // we check here for seeking because a programmatically seek where just
      // the currentTime has been changed does not trigger a proper seek event
      log(`waiting ${mediaElement.currentTime}`);
      this.checkSeeking();
      this.onBuffering();
    });
  }

  /**
   * Should only be calld when a mediaElement is attached
   */
  listenToMediaElementEvent(event: any, handler: any) {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    const boundHandler = handler.bind(this);

    this.mediaElementEventHandlers.push({event, handler: boundHandler});
    this.mediaElement.addEventListener(event, boundHandler, false);
  }

  onMaybeReady() {
    if (!this.needsReadyEvent || !this.mediaElement) {
      return;
    }

    this.needsReadyEvent = false;

    this.getCurrentPlaybackInfo();

    this.videoCompletionTracker.reset();
    this.videoCompletionTracker.setVideoDuration(this.mediaElement.duration);
  }

  /**
   * unregister the mediaElement and removes it from the class
   * will also remove all references to this adapter instance (listener, interceptor)
   */
  protected unregisterMediaElement() {
    if (!this.mediaElement) {
      throw new Error('No media element attached');
    }

    const mediaElement = this.mediaElement;

    this.mediaElementEventHandlers.forEach((item: {event: string; handler: any}) => {
      mediaElement.removeEventListener(item.event, item.handler);
    });

    // assign the original load func to the html video element remove references to `this`
    const originalLoadFunc = this.mediaElementOriginalLoadFn;
    if (originalLoadFunc != null) {
      mediaElement.load = originalLoadFunc;
    }

    this.mediaElement = undefined;
    this.mediaElementEventHandlers = [];
    this.mediaElementOriginalLoadFn = undefined;

    // we can clear `checkPlayheadProgress` timeout, as it is not needed anymore
    window.clearTimeout(this.bufferingTimeout);
  }

  onBuffering() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const {currentTime} = this.mediaElement;

    // this handler may be called multiple times
    // for one actual buffering-event occuring so lets guard from
    // triggering this event redundantly.
    if (this.isBuffering || (this.isPaused && !this.isSeeking)) {
      return;
    }

    if (this.isSeeking) {
      this.eventCallback(Event.SEEK, {currentTime});
    } else {
      this.eventCallback(Event.START_BUFFERING, {currentTime});
    }
    this.isBuffering = true;
  }

  onPaused(currentTime?: number) {
    if (this.isPaused) {
      return;
    }
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    if (!currentTime) {
      currentTime = this.mediaElement.currentTime;
    }

    this.eventCallback(Event.PAUSE, {currentTime});

    this.isPaused = true;
  }

  registerWindowEvents() {
    this.windowEventTracker.addEventListener('beforeunload', this.onPageClose.bind(this));
    this.windowEventTracker.addEventListener('unload', this.onPageClose.bind(this));
  }

  onPageClose() {
    if (!this.onBeforeUnLoadEvent) {
      this.onBeforeUnLoadEvent = true;
      const mediaElement = this.mediaElement;
      let currentTime: number | undefined;
      if (mediaElement != null) {
        currentTime = mediaElement.currentTime;
      }
      this.eventCallback(Event.UNLOAD, {currentTime});
    }
    this.release();
  }

  checkPlayheadProgress() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const mediaElement = this.mediaElement;
    if (mediaElement.paused) {
      this.onPaused();
    }

    clearTimeout(this.bufferingTimeout);

    this.bufferingTimeout = window.setTimeout(() => {
      if (mediaElement.paused || (mediaElement.ended && !this.isBuffering)) {
        return;
      }

      // Calculate the playback time difference (delta) to later verify if playback progress
      // has halted or slowed down. The "timeupdate" event in HTML5 media elements is
      // not reliable, especially on slower devices like TVs or when the JavaScript
      // main thread is blocked. These scenarios can lead to delays in
      // "timeupdate" event emissions, even though playback progresses normally in
      // the video rendering pipeline.
      const playingDeltaInSeconds = mediaElement.currentTime - this.previousMediaTimeInSeconds;

      // Minimum playback progress threshold to avoid incorrectly reporting buffering events.
      // A 20% margin is applied in relation to the Timeout to account for timing inaccuracies
      // and short stalling events. This is a tradeoff between overreporting and underreporting
      // buffering events as well as preventing buffering -> playing loops. (https://bitmovin.atlassian.net/browse/AN-4572)
      const minPlayingDeltaThresholdInSeconds = (HTML5InternalAdapter.BUFFERING_TIMECHANGED_TIMEOUT_IN_MS / 1000) * 0.8;

      // If the playback progress is less than the threshold, treat it as buffering.
      if (playingDeltaInSeconds < minPlayingDeltaThresholdInSeconds) {
        this.onBuffering();
      }
    }, HTML5InternalAdapter.BUFFERING_TIMECHANGED_TIMEOUT_IN_MS);
  }

  checkQualityLevelAttributes(silent = false) {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }

    const mediaElement = this.mediaElement;

    const qualityLevelInfo = this.getCurrentQualityLevelInfo();
    if (!qualityLevelInfo) {
      return;
    }

    const {bitrate, width, height} = qualityLevelInfo;

    if (bitrate != null) {
      if (this.qualityChangeService.shouldAllowVideoQualityChange(bitrate)) {
        this.qualityChangeService.setVideoBitrate(bitrate);
        const eventData = {
          width,
          height,
          bitrate,
          currentTime: mediaElement.currentTime,
        };

        if (!silent) {
          this.eventCallback(Event.VIDEO_CHANGE, eventData);
        }
      } else {
        this.qualityChangeService.setVideoBitrate(bitrate);
      }
    }
  }

  sourceChange(config: AnalyticsConfig) {
    this.stateMachine.callManualSourceChangeEvent(config, this.mediaElement?.currentTime);
  }

  getSelectedSubtitleFromMediaElement(mediaElement: any): SubtitleInfo | undefined {
    if (mediaElement.textTracks == null) {
      return undefined;
    }
    const textTrackList = mediaElement.textTracks;
    for (const attr of textTrackList) {
      if (attr.mode != null && attr.mode === 'showing') {
        const isSubtitleDisplayed = attr.language != null && attr.language.length > 0;
        return {
          enabled: isSubtitleDisplayed,
          language: isSubtitleDisplayed ? attr.language : undefined,
        };
      }
    }
    return {
      enabled: false,
    };
  }

  /**
   * Method to detect seeking events on platforms where seeking events are not reliable
   * This code is currently called in timeUpdate event and waiting event.
   * - We can't rely on the `seeking` and 'seeked' events on certain platforms (Tizen, WebOs, Safari, etc.)
   * - Another limitation is that we cannot rely on the time update event to be fired in regular intervals
   *   The time between two timeupdate events can be 1ms or 250ms for example. This code needs to handle this variance.
   */
  private checkSeeking() {
    if (!this.mediaElement) {
      throw new Error('No media attached');
    }
    const currentMediaTimeInSeconds = this.mediaElement.currentTime;

    // absolut difference of playback position compared with last call of method
    const playbackPositionDeltaInSeconds = Math.abs(currentMediaTimeInSeconds - this.previousMediaTimeInSeconds);

    // If the playback position has not changed more than 1 second we ignore it, to avoid
    // issuing of micro seeks. If not ignored the progressThreshold below might be too low, in order to
    // be reliable for such minor seeks. This is a tradeoff between overreporting and underreporting of seek events.
    if (playbackPositionDeltaInSeconds < 1) {
      this.previousClientTime = Date.now();
      return;
    }

    const currentClientTimeInMillis = Date.now();

    // Real time delta of method calls. How much time has passed since last call (in seconds)
    const clientTimeProgressInSeconds = (currentClientTimeInMillis - this.previousClientTime) / 1000;

    // we have to consider the playbackRate when calculating the threshold to trigger seeks
    // playbackRate of 3 progresses the playback triple the speed so the playbackPositionDelta
    // is also higher. This needs to be considered here
    // Adding 0.5 is a safety margin to avoid over reporting of seek events
    const thresholdModifier = this.mediaElement.playbackRate + 0.5;
    const progressThresholdInSeconds = clientTimeProgressInSeconds * thresholdModifier;

    // If the media time (current position of the playback) progresses much faster than real time
    // we consider this a time jump/seek
    if (playbackPositionDeltaInSeconds > progressThresholdInSeconds) {
      this.isSeeking = true;
      this.onPaused(this.previousMediaTimeInSeconds + clientTimeProgressInSeconds);
    }

    this.previousClientTime = Date.now();
  }
}

const log = (msg: string) => {
  const msgTime = new Date().toISOString().substring(11);
  const prefix = `${msgTime}[HTML5InternalAdapter]`;
  const logMsg = `${prefix}: ${msg}`;
  logger.log(logMsg);
};
