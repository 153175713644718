import {gql} from '@apollo/client';

export const GetAttendeeQuery = gql`
  query QueryAccessCodeSession {
    self {
      id
      attendeeTags
      attendee {
        id
        name
        email
      }
      chatTokens {
        token
      }
    }
  }
`;

export const VerifyAccessCodeMutation = gql`
  mutation VerifyAccessCode($showId: UUID!, $accessCode: String!) {
    verifyAccessCode(data: {showId: $showId, accessCode: $accessCode}) {
      id
      showId
      sessionToken
      attendeeTags
      attendee {
        id
        name
        email
      }
      chatTokens {
        token
      }
    }
  }
`;

export const VerifyPublicPasscode = gql`
  mutation VerifyPublicPasscode($data: VerifyPublicPasscodeInput!) {
    verifyPublicPasscode(data: $data) {
      __typename
      ... on PublicPasscodeOutput {
        id
        name
        email
        sessionToken
        chatTokens
        attendeeTags
      }
      ... on DataIntegrityError {
        code
        message
      }
    }
  }
`;
