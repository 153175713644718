"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJsonMapper = exports.getDynamoMapper = void 0;
const access_code_1 = require("./access-code");
const asset_1 = require("./asset");
const attendee_1 = require("./attendee");
const connection_1 = require("./connection");
const core_1 = require("./core");
const email_1 = require("./email");
const guest_session_1 = require("./guest-session");
const module_1 = require("./module");
const organization_1 = require("./organization");
const rate_limit_request_1 = require("./rate-limit-request");
const show_1 = require("./show");
const show_instruction_1 = require("./show-instruction");
const site_1 = require("./site");
const site_variable_1 = require("./site-variable");
const timestamp_json_1 = require("./timestamp-json");
const DynamoMapper = {
    AccessCode: access_code_1.AccessCodeDynamoMapper,
    Attendee: attendee_1.AttendeeDynamoMapper,
    Connection: connection_1.ConnectionDynamoMapper,
    Core: core_1.CoreDynamoMapper,
    EmailTransaction: email_1.EmailTransactionDynamoMapper,
    EmailTrigger: email_1.EmailTriggerDynamoMapper,
    GuestSession: guest_session_1.GuestSessionDynamoMapper,
    Module: module_1.ModuleDynamoMapper,
    Organization: organization_1.OrganizationDynamoMapper,
    RateLimitRequest: rate_limit_request_1.RateLimitRequestDynamoMapper,
    Show: show_1.ShowDynamoMapper,
    ShowInstruction: show_instruction_1.ShowInstructionDynamoMapper,
    Site: site_1.SiteDynamoMapper,
    SiteVariable: site_variable_1.SiteVariableDynamoMapper,
};
const JsonMapper = {
    AccessCode: access_code_1.AccessCodeJsonMapper,
    Asset: asset_1.AssetJsonMapper,
    AssetOutput: asset_1.OutputJsonMapper,
    Attendee: attendee_1.AttendeeJsonMapper,
    AudioConfig: asset_1.AudioConfigJsonMapper,
    CaptionConfig: asset_1.CaptionConfigJsonMapper,
    Connection: connection_1.ConnectionJsonMapper,
    Core: core_1.CoreJsonMapper,
    EmailTransaction: email_1.EmailTransactionJsonMapper,
    EmailTrigger: email_1.EmailTriggerJsonMapper,
    GuestSession: guest_session_1.GuestSessionJsonMapper,
    Module: module_1.ModuleJsonMapper,
    Organization: organization_1.OrganizationJsonMapper,
    RateLimitRequest: rate_limit_request_1.RateLimitRequestJsonMapper,
    Show: show_1.ShowJsonMapper,
    ShowInstruction: show_instruction_1.ShowInstructionJsonMapper,
    Site: site_1.SiteJsonMapper,
    SiteVariable: site_variable_1.SiteVariableJsonMapper,
    Stream: asset_1.StreamJsonMapper,
    Timestamps: timestamp_json_1.TimestampsJsonMapper,
    Transfer: asset_1.TransferJsonMapper,
    Watermark: asset_1.WatermarkJsonMapper,
};
/**
 * Retrieve a `DynamoDbMapper` for the given `kind`
 */
function getDynamoMapper(kind) {
    return DynamoMapper[kind];
}
exports.getDynamoMapper = getDynamoMapper;
/**
 * Retrieve a `JsonMapper` for the given `kind`
 */
function getJsonMapper(kind) {
    return JsonMapper[kind];
}
exports.getJsonMapper = getJsonMapper;
