/**
 * Extract the value at `key` from each element in `arr` and use `item[key]` as
 * the key in a `Map` key value store. Does nothing about collisions, so assumes
 * the values of `item[key]` are unique, if they are not unique the last item in
 * `arr` with the `item[key]` value "wins".
 */
export const arrayToLookup = (arr, key) => {
    const output = new Map();
    return arr.reduce((memo, x) => {
        const k = x[key];
        memo.set(k, x);
        return memo;
    }, output);
};
/**
 * Extract the value at `key` from each element in `arr` and use `item[key]` as
 * the key in a key value object store. Does nothing about collisions, so
 * assumes the values of `item[key]` are unique, if they are not unique the last
 * item in `arr` with the `item[key]` value "wins".
 */
export const arrayToRecord = (arr, key) => {
    const data = arrayToLookup(arr, key);
    return Object.fromEntries(data);
};
